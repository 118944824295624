import React from 'react';
import Header from "../pages/components/Header";
import Footer from "../pages/components/Footer";
import CTASection from "../pages/components/CTASection";
import Button from './components/Button';
import Faqs from './components/faqs';
import Done from '../images/done.svg';

const PRICING =[
  {
    title: "Basic",
    price: "$10",
    per: "per montth",
    features:[
       "Unlimited  Appointments ",
       "unlimited Clients",
       "unlimited Logins",
       "Unlimited staff Logins",
       "staff Report",
       "Sales Reports",
       "Appointment Reports",
       "Multiple Branch",
       "Customer History Management",
       "Online Booking Management",
       "Appoinment Calendar",
       "Staff Work Schedule",
       "Automatic Sms/Email Notification",
       "Daily Backups",
       "Slot blocker",
       "Payment Gateway integration",
       "Leave Management",
       "Business Timing management",
       "Advanced security",
    ]
  },
  {
    title: "Bussiness",
    price: "$30",
    per: "per montth",
    features:[
        "Unlimited  Appointments ",
        "unlimited Clients",
        "unlimited Logins",
        " Email Support",
        "Web Booking integration",
        "Instagram integration",
        "FB integration",
        "Whatsapp integration",
        "Sms campaigns",
        "Single Pagbe website",
        "Email news letter",
    ]
  },
  {
    title: "Enterprises",
    price: "$100",
    per: "per montth",
    features:[
       "Unlimited  Appointments ",
       "unlimited Clients",
       "unlimited Logins",
       "Phone Support",
       "MemberShip",
       "Membership subscription",
       "Coupons",
       "Training",
       "Wallet",
       "Offers",
       "Gift cards",
       "Customer feedBack System",
       "Mobile app for your branding",
       "Show your Work (With customer before & after image)",
    ]
  },
]

const FAQS = [
  {
      title: "How does the 14- days free trial work?",
      description: "premium Support is offered as part of the premium and premium plus subscription,where the support response ime is 5 minutes during business hours.We pay more attention to the clients who have Premium Support",
  },
  {
      title: "Can i upgarde or downgrade subscription?",
      description: "premium Support is offered as part of the premium and premium plus subscription,where the support response ime is 5 minutes during business hours.We pay more attention to the clients who have Premium Support",
  },
  {
      title: "what if i go over my maximum requests quota?",
      description: "premium Support is offered as part of the premium and premium plus subscription,where the support response ime is 5 minutes during business hours.We pay more attention to the clients who have Premium Support",
  },
  {
      title: "How Safe is my data?",
      description: "premium Support is offered as part of the premium and premium plus subscription,where the support response ime is 5 minutes during business hours.We pay more attention to the clients who have Premium Support",
  },
  {
      title: "What counts as a host?",
      description: "premium Support is offered as part of the premium and premium plus subscription,where the support response ime is 5 minutes during business hours.We pay more attention to the clients who have Premium Support",
  },
  {
      title: "Do you have any cancellation fees?",
      description: "premium Support is offered as part of the premium and premium plus subscription,where the support response ime is 5 minutes during business hours.We pay more attention to the clients who have Premium Support",
  },
  {
     title:"Cann i cancel subscription",
     description: "premium Support is offered as part of the premium and premium plus subscription,where the support response ime is 5 minutes during business hours.We pay more attention to the clients who have Premium Support",
  },
  {
      title:"What is premium Support?",
      description: "premium Support is offered as part of the premium and premium plus subscription,where the support response ime is 5 minutes during business hours.We pay more attention to the clients who have Premium Support",
  },
 
];

 function ToggleSwitch(){ 

  return(
    <center>  <label htmlFor="Toggle3" className="inline-flex items-center  bg-accentLight rounded-full cursor-pointer ">
    <input id="Toggle3" type="checkbox" className="hidden peer" />
    <span className="sm:px-16 py-4 lg:px-24 bg-accent rounded-full font-semibold peer-checked:bg-accentLight uppercase">Monthly</span>
    <span className="sm:px-16 py-4 lg:px-24 bg-accentLight rounded-full font-semibold peer-checked:bg-accent uppercase">Annually</span></label>
    </center> 
   
  )

}

function PricingPage() {
    return (
      <div>
        <Header/>
        <div className=' bg-white'> 
      <div className='container mx-auto md:px-16 md:py-24 sm:py-16 sm:px-0'>
            <h1 className='lg:text-4xl sm:text-3xl font-semibold text-center  md:text-black '>pricing & Features</h1>
            <h3 className='text-xl font-semibold text-center mb-4 py-10' >Flexible free trial,no credit card required.</h3>
            <ToggleSwitch/>
            <div className="grid grid-cols-3 p-8 md:overflow-auto sm:overflow-x-auto sm:grid-cols-[350px_350px_350px] pl-24 md:gap-16 md:py-24 sm:py-8  sm:gap-2">
                {PRICING.map(({title,price,per,features}) => (
                    <div key={title} className="border rounded-lg md:border-2 sm:border-4 p-5">
                       <h3 className='text-2xl font-semibold text-center  mb-4'>{title}</h3>
                        <h1 className='text-5xl font-semibold text-center py-3'>{price}</h1>
                        <p className='text-md text-center py-3 '>{per}</p>
                           <center>
                           <Button className="md:px-24 md:py-4 sm:px-8 sm:py-2 items-center rounded-full justify-center item-center  bg-accent font-semibold text-md text-white ">Try for Free</Button>
                            </center>
                        <hr className="my-8 w-full h-px bg-gray-200 border-0 "></hr>      
                       {features.map((features)=>(
                         <div  key={features}>
                            <div className="cursor-pointer flex py-2 " >
                            <Done/><ul className='px-4'>{features} </ul>
                            </div>
                         </div>        
                       ))}
                    </div>
                ))}
            </div>
            </div>
          <div className='container mx-auto md:px-16 md:py-10 sm:py-5 sm:px-8'>
            <h2 className='md:text-4xl font-semibold text-center py-10 sm:text-2xl'>FAQs</h2>
           {FAQS.map((faq) => (
             <div key={faq.title}>
            <Faqs
              title={faq.title}
              description={faq.description}
            />
            </div>
           ))}
           </div> 
        </div>
        <CTASection />
        <Footer/>
        </div>
    );
   
}

export default PricingPage;